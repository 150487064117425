import { Injectable } from '@angular/core';
import { createStore, select, setProp, withProps } from '@ngneat/elf';
import { excludeKeys, localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import {
  clearRequestsStatus,
  createRequestsStatusOperator,
  selectIsRequestPending,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { Observable, shareReplay } from 'rxjs';

import { SessionProps } from './interfaces/session.model.ts';

const initialSessionProps: SessionProps = {
  session_tokens: null,
  user: null,
  quiz_token: null,
  account: null,
};

const STORE_NAME = 'manpower-session-v1';

@Injectable({ providedIn: 'root' })
export class SessionRepository {
  private readonly store;

  readonly trackSessionRequestStatus;
  readonly sessionRequestLoading$: Observable<boolean>;

  readonly sessionTokenData$: Observable<SessionProps['session_tokens']>;
  readonly sessionQuizTokenData$: Observable<SessionProps['quiz_token']>;
  readonly user$: Observable<SessionProps['user']>;
  readonly account$: Observable<SessionProps['account']>;
  readonly isLoggedIn$: Observable<boolean>;

  constructor() {
    this.store = createStore(
      { name: STORE_NAME },
      withProps<SessionProps>(initialSessionProps),
      withRequestsStatus<'session'>(),
    );

    persistState(this.store, {
      key: STORE_NAME,
      storage: localStorageStrategy,
      // Need to exclude the request status from persistState to avoid bricking the app.
      // When a user refreshes the page while a request is still pending, the login button
      // will remain disabled forever or until local storage is cleared.
      // This should probably be excluded everywhere where we use withRequestsStatus and persistState
      source: () => this.store.pipe(excludeKeys(['requestsStatus'])),
    });

    this.trackSessionRequestStatus = createRequestsStatusOperator(this.store);

    this.sessionRequestLoading$ = this.store.pipe(selectIsRequestPending('session'));

    // #region Store selectors
    this.sessionTokenData$ = this.store.pipe(
      select(state => state.session_tokens),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    this.sessionQuizTokenData$ = this.store.pipe(
      select(state => state.quiz_token),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    this.user$ = this.store.pipe(
      select(state => state.user),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    this.account$ = this.store.pipe(
      select(state => state.account),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    this.isLoggedIn$ = this.store.pipe(
      select(state => !!state.session_tokens?.token_key),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    // #endregion
  }

  // #region Store setters
  setSessionTokens(tokens: SessionProps['session_tokens']): void {
    this.store.update(setProp('session_tokens', tokens));
  }

  setQuizToken(token: SessionProps['quiz_token']): void {
    this.store.update(setProp('quiz_token', token));
  }

  setUser(user: SessionProps['user']): void {
    this.store.update(setProp('user', user));
  }

  setAccount(account: SessionProps['account']): void {
    this.store.update(setProp('account', account));
  }
  // #endregion

  // #region Store getters
  getSessionTokens(): SessionProps['session_tokens'] {
    return this.store.getValue().session_tokens;
  }

  getQuizToken(): SessionProps['quiz_token'] {
    return this.store.getValue().quiz_token;
  }

  getUser(): SessionProps['user'] {
    return this.store.getValue().user;
  }

  getAccount(): SessionProps['account'] {
    return this.store.getValue().account;
  }

  isUserLoggedIn(): boolean {
    return !!this.store.getValue().session_tokens?.token_key;
  }
  // #endregion

  resetStore(): void {
    this.store.reset();
  }

  clearRequestStatus(): void {
    this.store.update(clearRequestsStatus());
  }
}
